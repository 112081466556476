import request from '@/plugins/axios'
// 添加词、组
export function setKeyWords(data) {
  return request({
    url: '/key',
    method: 'post',
    data
  })
}
// 列表数据
export function keyWordsPage(page, limit) {
  return request({
    url: `/key/${page}/${limit}`,
    method: 'post',
    data: {}
  })
}
// 编辑
export function putKeyWords(data) {
  return request({
    url: '/key',
    method: 'put',
    data
  })
}
// 删除
export function delKeyWords(id) {
  return request({
    url: `/key/${id}`,
    method: 'delete'
  })
}
// 详情
export function keyDetail(id) {
  return request({
    url: `/key/${id}`,
    method: 'get'
  })
}
