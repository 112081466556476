<template>
  <div class="comment-page">
    <div class="comment-page-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.channelId"
            style="width: 260px"
            placeholder="请输入频道ID"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.inputKey"
            style="width: 452px"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="searchArticlePage"
          >
            检索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】可以通过上面手动输入关键词或频道id后点击检索进行查询，也可以使用自己定义的关键词组点击查询进行查询
              </p>
              <p>
                【2】点击操作中的“监控”则可以对评论者的所有视频进行监控，监控后可在“频道方案”模块中查看，点击查看视频则可以查看视频详情及所有评论
              </p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="loading" class="comment-page-content">
      <div class="video-left">
        <div class="pagination-box">共检索到数据{{ total }}条</div>

        <div
          class="video-left-content"
          v-infinite-scroll="getCommentPage"
          :infinite-scroll-disabled="scrollDisabled"
          :infinite-scroll-distance="10"
        >
          <div class="content-item" v-for="(item, i) in listData" :key="i">
            <div class="content-item-left">
              <img :src="item.authorThumbnail" alt="" />
            </div>
            <div class="content-item-right">
              <div class="info-top">
                <div class="info-top-left">
                  <span>
                    {{ item.author }}&nbsp;&nbsp;{{ item.publishDate }}
                  </span>
                  <span>频道ID:{{ item.authChannelId }}</span>
                  <span class="a-box">
                    频道链接:
                    <a :href="item.authorUrl" target="_blank">
                      {{ item.authorUrl }}
                    </a>
                  </span>
                  <span>视频ID:{{ item.videoId }}</span>
                </div>
                <div class="info-top-right">
                  <el-popover
                    placement="bottom"
                    trigger="hover"
                    popper-class="comment-page-popover"
                  >
                    <div>
                      <div>
                        <img :src="require('@/assets/imgs/i21.png')" alt="" />
                        <span class="btn-1" @click="addChannel(item.authorUrl)">
                          监 控
                        </span>
                      </div>
                      <div style="margin-top:10px">
                        <img :src="require('@/assets/imgs/i20.png')" alt="" />
                        <span class="btn-1" @click="viewVideo(item)">
                          查看视频
                        </span>
                      </div>
                    </div>
                    <span slot="reference" class="head-info">
                      <img :src="require('@/assets/imgs/i7.png')" alt="" />
                    </span>
                  </el-popover>
                </div>
              </div>
              <div class="info-contentText" v-html="item.contentText"></div>
            </div>
          </div>
          <li v-if="listData.length > 0">{{ nomoreText }}</li>
        </div>
      </div>
      <div class="comment-right">
        <div class="comment-right-title">关键词方案</div>
        <div class="comment-right-title-border"></div>
        <div class="comment-right-record">
          <ul>
            <li v-for="(item, i) in planList" :key="i">
              <span
                @click.prevent="selectKey(i, item)"
                class="history-words"
                :class="selectKeyIndex == i ? 'select-obj' : ''"
              >
                <!--  -->
                {{ item.groupName }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { commentPage, setChannel } from '@/api/channel.js'
import { keyWordsPage } from '@/api/keywords.js'
export default {
  data() {
    return {
      loading: false,
      planList: [],
      searchForm: {
        inputKey: '',
        channelId: ''
      },
      copySearchForm: {},
      // 当前页
      current: 1,
      // 每页记录数
      limit: 30,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: {},
      tagIndexObj: {},
      listData: [],
      //
      selectKeyIndex: null,
      //
      nomoreText: '加载中...',
      scrollDisabled: false
    }
  },

  created() {
    this.getPlan()
  },
  methods: {
    // 查看新闻
    lookNews(id) {
      this.$router.push({
        path: '/video/detail',
        query: {
          videoId: id
        }
      })
    },
    searchArticlePage() {
      this.current = 1
      this.copySearchForm = Object.assign({}, this.searchForm)
      this.listData = []
      this.selectRecordIndex = null
      this.selectKeyIndex = null
      this.getCommentPage()
    },
    getCommentPage() {
      this.loading = true
      this.scrollDisabled = true

      commentPage(this.current, this.limit, this.copySearchForm).then(data => {
        this.loading = false
        this.total = data.all
        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    // 关键词数据
    getPlan() {
      keyWordsPage(1, 1000).then(data => {
        //
        this.planList = data.items
      })
    },

    currentChange(val) {
      this.current = val
      this.getCommentPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getCommentPage()
    },

    // 选择关键词
    selectKey(i, row) {
      if (row.id == this.copySearchForm.userKeywordId) {
        this.selectKeyIndex = null
        this.copySearchForm = {}
      } else {
        this.selectKeyIndex = i
        this.copySearchForm = Object.assign({}, { userKeywordId: row.id })
      }

      this.current = 1
      this.listData = []
      this.getCommentPage()
    },
    //
    viewVideo(row) {
      this.$router.push({
        path: '/video/detail/' + row.videoId,
        query: {
          videoTitle: row.videoId + ' 视频'
        }
      })
    },
    //
    addChannel(url) {
      let obj = {
        channelUrl: url
      }
      setChannel(obj).then(res => {
        this.$message({
          type: 'success',
          message: '监控成功！'
        })
      })
    }
  }
}
</script>

<style lang="scss">
.comment-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .comment-page-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 9px 0 rgb(82 63 105 / 18%);
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
    .head-tag-box-margin {
      margin: 10px 0 15px 15px;
    }
    .head-tag-box {
      .head-tag {
        padding: 7px 13px;
        background: rgb(241, 241, 241);
        border: 0px solid #000000;
        border-radius: 15px;
        border: 1px solid rgb(224, 224, 224);
        margin-right: 15px;
        user-select: none;
        cursor: pointer;
      }
      .select-tag {
        color: white;
        background: #131212;
        border: none;
      }
    }
  }
  .pagination-box {
    margin-bottom: 5px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 12px;
    color: #4b4b4b;
    background: #ffffff;
    border-radius: 5px;
  }
  .comment-page-content {
    width: 100%;
    height: calc(100% - 55px);
    margin-top: 5px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .video-left {
      width: calc(100% - 270px);
      height: 100%;

      .video-left-content {
        border-radius: 5px;
        padding: 14px;
        background: #ffffff;
        width: 100%;
        height: calc(100% - 45px);
        overflow: hidden;
        overflow-y: auto;
        .content-item {
          width: 100%;
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: 20px;
          margin-bottom: 20px;

          overflow: hidden;
          display: flex;
          justify-content: space-between;
          .content-item-left {
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .content-item-right {
            width: calc(100% - 50px);
            .info-top {
              display: flex;
              justify-content: space-between;
              .info-top-left {
                width: calc(100% - 20px);

                white-space: nowrap;
                overflow: hidden;
                > span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #1a1a1b;
                  margin-right: 30px;
                }
                .a-box {
                  &:hover {
                    color: #0b81cf;
                    text-decoration: underline;
                  }
                }
              }
              .info-top-right {
                display: none;
                .head-info {
                  cursor: pointer;
                  img {
                    width: 16px;

                    object-fit: cover;
                    vertical-align: middle;
                  }
                  span {
                    font-size: 12px;
                    font-weight: 300;
                    color: #4b4b4b;
                  }
                }
              }
            }
            .info-contentText {
              font-size: 14px;
              margin-top: 10px;
              font-weight: 300;
              color: #030303;
              line-height: 21px;
            }
          }
          &:hover {
            .info-top .info-top-right {
              display: block;
            }
          }
        }
        li {
          list-style: none;
          text-align: center;
          color: #555555;
          font-size: 12px;
        }
      }
    }
    .comment-right {
      width: 260px;
      height: calc(100% - 10px);
      overflow: auto;
      border-radius: 5px;
      padding: 14px;
      overflow: auto;
      background: #ffffff;
      border: 1px solid #e5e5e5;

      .comment-right-title {
        padding-left: 10px;
        border-left: 4px solid #694638;
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
      }
      .comment-right-title-border {
        border-bottom: 1px solid #e5e5e5ce;
        margin: 13px 0;
      }
      .comment-right-record {
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        // background: #2a2a2a;
        padding-top: 10px;
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin-bottom: 15px;
            .history-words {
              padding: 5px 10px;
              background: #efefef;
              border: 0px solid #000000;
              border-radius: 20px;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 300;
              color: #2a2a2a;
              cursor: pointer;
              user-select: none;
              position: relative;
              .close-log {
                position: absolute;
                top: -5px;
                right: -5px;
                display: none;
              }
              &:hover {
                .close-log {
                  display: block;
                }
              }
            }
            .select-obj {
              background: #292828;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  //
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
.video-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
.comment-page-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
</style>
